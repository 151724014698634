import { defineStore } from "pinia";
import { qualityTelegramSituation, banner } from "@/apis/home";
import dayjs from "dayjs";
import qs from "qs";

export const colors = {
  telegramType: "#E8684A",
  productName: "#FFA900",
  tag: "#E8684A",
  inspRegProvinceName: "#5B8FF9",
};

export let getTags = (option) => {
  return [
    {
      name: option.telegramType,
      color: option.telegramType === "监督抽检" ? "#E8684A" : "#34C759",
    },
    ...option.productName.split(",").map((item) => {
      return { name: item, color: colors["productName"] };
    }),
    {
      name: [option.inspRegProvinceName, option.inspRegCityName]
        .filter(Boolean)
        .join("/"),
      color: colors["inspRegProvinceName"],
    },
  ].filter((item) => item.name);
};

export const useStore = defineStore("home", {
  state: () => {
    return {
      banner: [],
      totalQualityTelegramSortList: [], // 质量电报
      telegramInspectionQualifiedList: [], // 产品统计
      eamlessScroll: [],
    };
  },
  actions: {
    async qualityTelegramSituation(...args) {
      if (this.totalQualityTelegramSortList.length) return;
      var res = await qualityTelegramSituation(...args);
      this.totalQualityTelegramSortList = res.totalQualityTelegramSortList;
      this.telegramInspectionQualifiedList =
        res.telegramInspectionQualifiedList;
    },
    async requestBanner(data) {
      if (this.banner.length) return;
      var res = await banner(qs.stringify(data));
      this.banner = res.rows;
    },
    async requestEamlessScroll(data) {
      if (this.eamlessScroll.length) return;
      var res = await banner(qs.stringify(data));
      this.eamlessScroll = res.rows;
    },
  },
  getters: {
    _totalQualityTelegramSortList() {
      return this.totalQualityTelegramSortList.reduce((result, item) => {
        let t = dayjs(item.pubTime).format("YYYY-MM-DD");
        if (!result[t]) result[t] = [];

        result[t].push(item);

        return result;
      }, {});
    },
  },
});
