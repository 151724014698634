import { defineStore } from "pinia";

const getImageSize = url => {
  return new Promise(resolve => {
    var img = document.createElement("img");
    img.src = url;

    img.onload = () => {
      // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };
  });
};

const handleStr = str => {
  return str.slice(2).split("@")[0];
}; // export const useStore = defineStore("cooperation", {
//   state() {
//     return {
//       imageList: [],
//     };
//   },
//   actions: {
//     async getImageList() {
//       if (this.imageList.length) return;
//       this.imageList = [];
//       let req = require.context("@/assets/cooperation");
//       var imageListRequest = req.keys().map(async (key) => {
//         let url = require("@/assets/cooperation/" + key.slice(2));
//         var { width, height } = await getImageSize(url);
//         return {
//           key,
//           url,
//           style: {
//             width: width / 2 + "px",
//             height: height / 2 + "px",
//           },
//         };
//       });
//       var result = await Promise.all(imageListRequest);
//       this.imageList = result.sort((a, b) => {
//         return handleStr(a.key) - handleStr(b.key);
//       });
//     },
//   },
// });


export const useStore = defineStore("cooperation", function () {
  let imageList = ref([]);

  const getImageList = async () => {
    if (imageList.value.length) return;
    imageList.value = [];

    let req = require.context("@/assets/cooperation");

    var imageListRequest = req.keys().map(async key => {
      let url = require("@/assets/cooperation/" + key.slice(2));

      var {
        width,
        height
      } = await getImageSize(url);
      return {
        key,
        url,
        style: {
          width: width / 2 + "px",
          height: height / 2 + "px"
        }
      };
    });
    var result = await Promise.all(imageListRequest);
    imageList.value = result.sort((a, b) => {
      return handleStr(a.key) - handleStr(b.key);
    });
  };

  return {
    imageList,
    getImageList
  };
});