import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-004bad9d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "two"
};
const _hoisted_2 = {
  class: "content-box"
};
import { ref } from "vue";
import Area from "./Area.vue";
import Product from "./Product.vue";
import Category from "./Category.vue";
import { useBmap, getMapApi } from "@/useCharts/bmap";
export default {
  __name: 'index',

  setup(__props) {
    const carouselRef = ref(null);
    const activeIndex = ref("industrialAggQuota");
    const productLoaded = ref(false);
    const categoryLoaded = ref(false);

    let loadProduct = async () => {
      await import("echarts/extension/bmap/bmap");
      await getMapApi();
      productLoaded.value = true;
    };

    loadProduct();

    const tabChange = async name => {
      if (name === "categoryQualityDiagramQuota") {
        categoryLoaded.value = true;
      }

      carouselRef.value.setActiveItem(name);
    };

    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      const _component_el_carousel_item = _resolveComponent("el-carousel-item");

      const _component_el_carousel = _resolveComponent("el-carousel");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tabs, {
        class: "demo-tabs",
        modelValue: activeIndex.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeIndex.value = $event),
        onTabChange: tabChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "产业聚集指数",
          name: "industrialAggQuota"
        }), _createVNode(_component_el_tab_pane, {
          label: "区域聚集指数",
          name: "regionQualityQuota"
        }), _createVNode(_component_el_tab_pane, {
          label: "品类质量指数",
          name: "categoryQualityDiagramQuota"
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_carousel, {
        ref_key: "carouselRef",
        ref: carouselRef,
        autoplay: false,
        "indicator-position": "none",
        loop: false,
        arrow: "never",
        height: "550px",
        style: {
          "margin-top": "50px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_carousel_item, {
          name: "industrialAggQuota"
        }, {
          default: _withCtx(() => [productLoaded.value ? (_openBlock(), _createBlock(Product, {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_carousel_item, {
          name: "regionQualityQuota"
        }, {
          default: _withCtx(() => [_createVNode(Area)]),
          _: 1
        }), _createVNode(_component_el_carousel_item, {
          name: "categoryQualityDiagramQuota"
        }, {
          default: _withCtx(() => [categoryLoaded.value ? (_openBlock(), _createBlock(Category, {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 512)])]);
    };
  }

};