import { unref as _unref, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2ecbbcc4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "Category"
};
const _hoisted_2 = {
  style: {
    "flex": "1",
    "height": "100%"
  },
  ref: "wrapChartRef",
  "element-loading-background": "transparent"
};
import { useBar, useLine, createPagination } from "@/useCharts/index.js";
import Tab from "./Tab";
import { useStore } from "./store";
import { ref, watch, onMounted, unref } from "vue";
import { chunk } from "lodash-es";
import { useOffsetPagination } from "@vueuse/core";
export default {
  __name: 'Category',

  setup(__props) {
    let store = useStore();
    let active = ref();
    let lineChartRef = ref(null);
    var {
      option
    } = useBar();
    option.title.text = "品类质量状况参考指数";

    option.tooltip.formatter = ([item]) => {
      return `
    ${item.name}<br />
    <div class="dot" style="background-color: ${item.color}"></div> ${item.data.tValue}
  `;
    };

    const updateBar = () => {
      option.xAxis.data = store.secondCateQualityRateList.map(item => item.name);
      option.series[0].data = store.secondCateQualityRateList.map(item => ({ ...item,
        tValue: (item.value * 100).toFixed(2),
        value: (item.value * 100).toFixed(2)
      }));
    };

    const init = async value => {
      await store.requestCategoryQualityDiagramQuota({
        firstCategoryId: value
      });
      updateBar();
      updateLine();
    };

    onMounted(async () => {
      store.$loading.requestCategoryQualityDiagramQuota = true;
      await store.requestFirstCateQualifiedRate();
      active.value = store.firstCateQualifiedRate[0].categoryId;
    });
    watch(active, init);
    var {
      option: optionLine
    } = useLine();
    optionLine.series = [];
    optionLine.title.text = "品类质量状况参考指数变化图";

    const updateLine = () => {
      optionLine.xAxis.data = store.n5SecondCateRateList[0].source[0];
      let series = store.n5SecondCateRateList.map(item => {
        return {
          type: "line",
          name: item.name,
          data: item.source[1].map(o => (o * 100).toFixed(2))
        };
      });
      let source = chunk(series, 5);

      const onPageChange = ({
        currentPage = 1
      }) => {
        optionLine.series = source[unref(currentPage) - 1];
        optionLine.legend.data = source[unref(currentPage) - 1].map(item => item.name);
      };

      onPageChange({});
      const {
        currentPage,
        currentPageSize,
        pageCount,
        isFirstPage,
        isLastPage,
        prev,
        next
      } = useOffsetPagination({
        total: series.length,
        page: 1,
        pageSize: 5,
        onPageChange
      });
      createPagination({
        prev,
        next,
        isFirstPage,
        isLastPage,
        currentPage,
        currentPageSize,
        pageCount
      }, lineChartRef.value.getDom());
    };

    return (_ctx, _cache) => {
      const _component_v_chart = _resolveComponent("v-chart");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_unref(Tab), {
        style: {
          "width": "388px"
        },
        modelValue: _unref(active),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(active) ? active.value = $event : active = $event)
      }, null, 8, ["modelValue"]), [[_directive_loading, _unref(store).$loading.requestFirstCateQualifiedRate]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_v_chart, {
        style: {
          "width": "100%",
          "height": "50%"
        },
        autoresize: "",
        option: _unref(option)
      }, null, 8, ["option"]), _createVNode(_component_v_chart, {
        style: {
          "width": "100%",
          "height": "calc(50% - 20px)",
          "margin-top": "20px"
        },
        autoresize: "",
        ref_key: "lineChartRef",
        ref: lineChartRef,
        option: _unref(optionLine),
        "update-options": {
          replaceMerge: ['series', 'dataZoom']
        }
      }, null, 8, ["option"])])), [[_directive_loading, _unref(store).$loading.requestCategoryQualityDiagramQuota]])]);
    };
  }

};