import "core-js/modules/es.array.includes.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = ["src", "alt", "onClick"];
import { onMounted, ref } from "vue";
import { useStore } from "../../store";
import { useRouter } from "vue-router";
export default {
  __name: 'Banner',

  setup(__props) {
    let store = useStore();
    let router = useRouter();
    let carouselRef = ref();
    store.requestBanner({
      contentType: "lbt"
    }).then(() => {
      carouselRef.value.setActiveItem(0);
    });

    let contentImg = contentImg => {
      if (!contentImg) return "";
      const toContentImg = JSON.parse(contentImg);

      if (toContentImg.length) {
        return toContentImg[0].path;
      }

      return '';
    };

    const handleClickItem = ({
      contentType,
      id,
      contentDescription
    }) => {
      if (contentType.includes("report")) {
        router.push({
          path: "/reportDetails",
          query: {
            id: id
          }
        });
      } else if (contentType.includes("database")) {
        router.push({
          path: "/database"
        });
      } else {
        window.open(contentDescription, "_blank");
      }
    };

    return (_ctx, _cache) => {
      const _component_el_carousel_item = _resolveComponent("el-carousel-item");

      const _component_el_carousel = _resolveComponent("el-carousel");

      const _directive_loading = _resolveDirective("loading");

      return _withDirectives((_openBlock(), _createBlock(_component_el_carousel, {
        loop: "",
        style: {
          "min-height": "380px"
        },
        "initial-index": 0,
        ref_key: "carouselRef",
        ref: carouselRef
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).banner, item => {
          return _openBlock(), _createBlock(_component_el_carousel_item, {
            key: item.id
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              style: {
                "cursor": "pointer",
                "border-radius": "2px"
              },
              width: "742",
              height: "380",
              src: _unref(contentImg)(item.contentImg),
              alt: item.contentDescription,
              onClick: $event => handleClickItem(item)
            }, null, 8, _hoisted_1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })), [[_directive_loading, _unref(store).$loading.requestBanner]]);
    };
  }

};