import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-55fefe95"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "tab__item--wrap"
};
const _hoisted_3 = {
  class: "tab__item--icon"
};
const _hoisted_4 = ["src", "alt"];
const _hoisted_5 = {
  class: "tab__item--name"
};
const _hoisted_6 = {
  class: "tab__item--num"
};
import { onMounted, ref } from "vue";
import { initials } from "@/utils/initials";
import { useStore } from "./store";
export default {
  __name: 'Tab',
  props: {
    modelValue: [String, Number]
  },
  emits: ["update:modelValue", "change"],

  setup(__props, {
    emit
  }) {
    const props = __props;
    let store = useStore();

    const handleClickItem = item => {
      emit("update:modelValue", item.categoryId);
      emit("change", item);
    };

    return (_ctx, _cache) => {
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");

      return _openBlock(), _createBlock(_component_el_scrollbar, {
        tag: "ul",
        class: "tab",
        height: "550px"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).firstCateQualifiedRate, item => {
          return _openBlock(), _createElementBlock("li", {
            key: item.categoryId,
            onClick: $event => handleClickItem(item)
          }, [_createElementVNode("div", {
            class: _normalizeClass(['tab__item', {
              active: _unref(props).modelValue === item.categoryId
            }])
          }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: require(`@/assets/category/${_unref(initials)(item.name)}.png`),
            alt: item.name
          }, null, 8, _hoisted_4)]), _createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1)]), _renderSlot(_ctx.$slots, "num", {}, () => [_createElementVNode("span", _hoisted_6, _toDisplayString((item.value * 100).toFixed(2)), 1)])], 2)], 8, _hoisted_1);
        }), 128))]),
        _: 3
      });
    };
  }

};