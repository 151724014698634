import random from "lodash/random";
import { onMounted, computed, reactive } from "vue";
const color1 = "#E8684A";
const color2 = "#5B8FF9";
const color3 = "#F6BD15";
const color4 = "#5D7092";

export let tagList = reactive({
  致伤病: color1,
  其他: color1,
  致财产损失: color1,
  产品质量安全舆情: color1,
  产品致伤: color1,
  事件: color1,
  食品致伤: color1,
  监督抽检: color1,

  市抽: color2,
  省抽: color2,
  其他地区抽检: color2,
  国抽: color2,
  县区抽: color2,

  产品: color3,
  食品: color3,
  医疗器械: color3,
  化妆品: color3,
  药品: color3,
  特种设备: color3,

  非抽检公告: color4,
  更正公告: color4,
  召回公告: color4,
  抽检公告: color4,
  核查处置情况公告: color4,
  逾期不改正公告: color4,

  初筛: "#5AD8A6",
  "央视《每周质量报告》": "#9270CA",
  存疑: "#FF9D4D",
  生产企业核查: "#6DC8EC",
  去重: "#269A99",
  比较试验: "#FF98C3",
});

// return (item) => [
//         { name: item.telegramType, color: item.telegramType === '监督抽检' ? '#E8684A' : '#34C759' },
//         ...item.productNames.map((one) => ({ color: '#FFA900', name: one })).slice(0, 3),
//         ...item.area.map((one) => ({ color: '#5B8FF9', name: one })),
//         ...item.tag.map((one) => ({ color: '#E8684A', name: one })),
//       ].filter(Boolean);
// option.telegramType,
//                     ...option.tag,
//                     option.productName,
//                     option.regProvinceName,
const useTagColor = () => {
  if (localStorage.tagList) {
    tagList = JSON.parse(localStorage.tagList);
  }

  let getColor = (tag) => {
    if (!tagList[tag]) {
      tagList[tag] = `rgb(${random(0, 255)}, ${random(0, 255)}, ${random(
        0,
        255
      )})`;
      localStorage.tagList = JSON.stringify(tagList);
    }
    return tagList[tag];
  };

  return {
    getColor,
  };
};

export default useTagColor;
