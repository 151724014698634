import { reactive, watch } from "vue";
import { clamp } from "lodash-es";

export const useBar = () => {
  let queue = [];
  let option = reactive({
    backgroundColor: "#fff",
    color: ["#3677CB"],
    title: {
      text: "",
      subtext: "",
      top: 20,
      textStyle: {
        fontSize: 16,
      },
      subtextStyle: {
        fontSize: 12,
      },
      left: "center",
    },
    dataZoom: [
      {
        show: false,
        type: "inside",
        start: 0,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
      {
        show: true,
        type: "slider",
        start: 0,
        end: 100,
        height: 8,
        zoomLock: true,
        brushSelect: false,
        showDataShadow: false,
        showDetail: false,
        bottom: 10,
      },
    ],
    grid: {
      left: 80,
      right: 50,
      top: 60,
      bottom: 50,
    },
    legend: {
      data: [],
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    // toolbox: {
    //   feature: {
    //     // saveAsImage: {},
    //     myTable3: {
    //       show: false,
    //       title: "计算方式",
    //       icon: "path://M 512 85.3333 c -235.733 0 -426.667 190.933 -426.667 426.667 s 190.933 426.667 426.667 426.667 s 426.667 -190.933 426.667 -426.667 s -190.933 -426.667 -426.667 -426.667 Z M 554.667 725.333 l -85.3333 0 l 0 -256 l 85.3333 0 l 0 256 Z M 554.667 384 l -85.3333 0 l 0 -85.3333 l 85.3333 0 l 0 85.3333 Z",
    //       onclick: this.showCalculate,
    //     },
    //     myTable2: {
    //       show: true,
    //       title: "保存为图片",
    //       icon: `image://${require("@/assets/img/download.svg")}`,
    //       onclick: this.save,
    //     },
    //     myBack: {
    //       show: false,
    //       title: "返回上级",
    //       icon: "path://M764.28847787 408.96539307c51.24041387 30.5463296 114.11019093 112.9447424 140.2667008 212.12910933 26.15978667 99.19092053 21.82239573 188.00858453 21.82239573 188.00858453s-33.86682027-54.6963456-47.59770453-73.87327146c-13.73088427-19.17146453-71.58934187-90.61116587-161.3955072-132.0157184-89.80616533-41.40346027-239.91855787-34.03066027-239.91855787-34.03066027v172.4121088L63.75560533 448.55241387l413.7091072-293.04531627v173.99808s99.38315947 7.4809344 151.3324544 18.23757653c87.49929813 18.12179627 135.49131093 61.22263893 135.49131094 61.22263894z m0 0",
    //       onclick: this.back,
    //     },
    //     myTable: {
    //       title: "数据视图",
    //       icon: `image://${require("@/assets/img/dataview.svg")}`,
    //       onclick: this.onDataView,
    //     },
    //   },
    // },
    xAxis: {
      type: "category",
      data: [],
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        interval: 0, //使x轴上的文字显示完全,
      },
    },
    yAxis: {},
    series: [
      {
        data: [],
        type: "bar",
        barMaxWidth: "30",
      },
    ],
  });

  watch(
    () => option.series,
    (value) => {
      let data = value[0].data;
      option.dataZoom[1].show = data.length > 6;
      let end = clamp((6 / data.length) * 100 - 5, 20, 100);

      option.dataZoom.forEach((item) => {
        item.end = end;
      });
    },
    { deep: true }
  );

  return {
    option,
    queue,
  };
};
