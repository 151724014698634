import { reactive, watch, nextTick, h, render, onMounted } from "vue";
import * as echarts from "echarts/core";
import { CaretLeft, CaretRight } from "@element-plus/icons-vue";
import { ElIcon } from "element-plus";

const Pagination = ({
  pageCount,
  isFirstPage,
  currentPage,
  isLastPage,
  next,
  prev,
}) => {
  if (pageCount.value <= 1) return;
  let style = {
    position: "absolute",
    right: "10px",
    top: "50px",
    width: "100px",
    height: "22px",
    display: "flex",
    alignItems: "center",
  };

  return (
    <div style={style}>
      <ElIcon
        size={24}
        color={isFirstPage.value ? "#AAAAAA" : "#2F4554"}
        style="cursor: pointer;"
        onClick={() => {
          if (isFirstPage.value) return;
          prev();
        }}
      >
        <CaretLeft />
      </ElIcon>
      <span style="font-size: 12px; color: #666">
        {currentPage.value} / {pageCount.value}
      </span>
      <ElIcon
        size={24}
        color={isLastPage.value ? "#AAAAAA" : "#2F4554"}
        style="cursor: pointer;"
        onClick={() => {
          if (isLastPage.value) return;
          next();
        }}
      >
        <CaretRight />
      </ElIcon>
    </div>
  );
};

export const createPagination = (options, dom) => {
  let instance = h(Pagination, options);

  render(instance, dom);

  return instance;
};

export const useLine = () => {
  let queue = [];
  let option = reactive({
    backgroundColor: "#fff",
    trigger: "axis",
    title: {
      text: "",
      subtext: "",
      top: 20,
      textStyle: {
        fontSize: 16,
      },
      subtextStyle: {
        fontSize: 12,
      },
      left: "center",
    },
    legend: {
      show: true,
      top: 50,
      // type: "scroll",
      width: "80%",
      data: [],
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    // toolbox: {
    //   feature: {
    //     myTable3: {
    //       show: true,
    //       title: "计算方式",
    //       icon: `image://${require("@/assets/img/info.svg")}`,
    //       onclick: this.showCalculate,
    //       emphasis: {
    //         iconStyle: {
    //           color: "#ccc",
    //         },
    //       },
    //     },
    //     myTable2: {
    //       show: true,
    //       title: "保存为图片",
    //       icon: `image://${require("@/assets/img/download.svg")}`,
    //       onclick: this.save,
    //       emphasis: {
    //         iconStyle: {
    //           color: "#ccc",
    //         },
    //       },
    //     },
    //     myTable: {
    //       title: "数据视图",
    //       icon: `image://${require("@/assets/img/dataview.svg")}`,
    //       emphasis: {
    //         iconStyle: {
    //           color: "#ccc",
    //         },
    //       },
    //       onclick: this.onDataView,
    //     },
    //   },
    // },
    grid: {
      left: 80,
      top: 100,
      right: 50,
      bottom: 30,
    },
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        show: true,
        color: "#848484",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#ccc",
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "#848484",
      },
    },
    series: [
      {
        data: [],
        type: "line",
        areaStyle: {
          opacity: 0.25,
        },
        label: {
          show: true,
          color: "#000",
        },
        lineStyle: {
          width: 2,
        },
        itemStyle: {
          color: "#5B8FF9",
        },
      },
    ],
  });

  return {
    option,
    queue,
  };
};
