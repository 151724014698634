import { reactive, watch } from "vue";
import { clamp } from "lodash-es";
import config from "@/utils/config";
import styleJson from "./styleJson";

export const getMapApi = (() => {
  let flag = false;
  return () => {
    if (flag) return;
    flag = true;

    // if (document.location.protocol.startsWith("https:")) {
    //   // window.HOST_TYPE = "2";
    //   // <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
    //   let meta = document.createElement("meta");
    //   meta["httpEquiv"] = "Content-Security-Policy";
    //   meta["content"] = "upgrade-insecure-requests";
    //   document.head.appendChild(meta);
    // }

    return new Promise((resolve, reject) => {
      // var mapScript = document.createElement("script");
      // mapScript.src = `//api.map.baidu.com/getscript?v=3.0&ak=${config.ak}`;
      // mapScript.type = "text/javascript";
      // document.head.appendChild(mapScript);

      const url = "https://ssl.gdcers.com";
      const map_server = url + "/offlinemap/" + "dark" + "/";
      const map_js = url + "/offlinemap/map.js";
      // 使用离线地图
      window.offmapcfg = {
        home: map_server,
        imgext: ".png",
      };
      const mapScript = document.createElement("script");
      mapScript.src = map_js;
      document.documentElement.appendChild(mapScript);
      mapScript.onload = resolve;
      mapScript.onerror = reject;
    });
  };
})();

export const effectScatter = () => {
  return {
    type: "effectScatter",
    coordinateSystem: "bmap",
    rippleEffect: {
      brushType: "stroke",
    },
    itemStyle: {
      color: "rgba(221, 218, 37, 0.8)",
      shadowBlur: 10,
      shadowColor: "rgba(108, 116, 16, 1)",
    },
    colorBy: "series",
    symbolSize(val) {
      const min = 10;
      const max = 70;
      return min + (val[2] / scaleMax) * (max - min);
    },
    tooltip: {
      borderColor: "transparent",
      show: true,
      formatter({ name, value }) {
        return `
          ${name}:${value[2]}
        `;
      },
    },
    label: {
      formatter: "{b}",
      position: "right",
      show: true,
      color: "rgba(221, 218, 37, 1)",
    },
    data: [],
  };
};

export const useBmap = () => {
  let queue = [];

  let option = reactive({
    backgroundColor: "transparent",
    title: {
      text: "",
      subtext: "",
      left: "center",
      textStyle: {
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
    },
    bmap: {
      center: [104.114129, 37.550339],
      zoom: 5,
      roam: true,
      mapOptions: {
        minZoom: 4,
        maxZoom: 7,
      },
      mapStyle: {
        styleJson: [
          {
            featureType: "water",
            elementType: "all",
            stylers: {
              color: "#044161",
            },
          },
          {
            featureType: "land",
            elementType: "all",
            stylers: {
              color: "#004981",
            },
          },
          {
            featureType: "boundary",
            elementType: "geometry",
            stylers: {
              color: "#064f85",
            },
          },
          {
            featureType: "railway",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry",
            stylers: {
              color: "#004981",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.fill",
            stylers: {
              color: "#005b96",
              lightness: 1,
            },
          },
          {
            featureType: "highway",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry",
            stylers: {
              color: "#004981",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.fill",
            stylers: {
              color: "#00508b",
            },
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "green",
            elementType: "all",
            stylers: {
              color: "#056197",
              visibility: "off",
            },
          },
          {
            featureType: "subway",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "manmade",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "local",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "boundary",
            elementType: "geometry.fill",
            stylers: {
              color: "#029fd4",
            },
          },
          {
            featureType: "building",
            elementType: "all",
            stylers: {
              color: "#1a5787",
            },
          },
          {
            featureType: "label",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
        ],
      },
    },
    series: [effectScatter()],
  });

  return {
    option,
    queue,
  };
};
