import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home"
};
import One from "./components/one";
import Two from "./components/two";
import Three from "./components/three";
import Four from "./components/four";
import EamlessScroll from "./components/EamlessScroll";
import { useStore } from "./store";
export default {
  __name: 'index',

  setup(__props) {
    let store = useStore();
    store.qualityTelegramSituation();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(EamlessScroll)), _createVNode(_unref(One)), _createVNode(_unref(Two)), _createVNode(_unref(Three)), _createVNode(_unref(Four))]);
    };
  }

};