import { defineStore } from "pinia";
import { qualityQuotaSituation, firstCateQualifiedRate } from "@/apis/home";

let regionQualityQuotaMap = new Map();
let industrialAggQuotaMap = new Map();
let categoryQualityDiagramQuotaMap = new Map();

setInterval(() => {
  regionQualityQuotaMap = new Map();
  industrialAggQuotaMap = new Map();
  categoryQualityDiagramQuotaMap = new Map();
}, 1000 * 60);

export const useStore = defineStore("homeTab", {
  state: () => {
    return {
      firstCateQualifiedRate: [], //产品分类

      regionQualityQuota: [],
      industrialAggQuota: [],
      n5SecondCateRateList: [],
      secondCateQualityRateList: [],
    };
  },
  actions: {
    async request() {
      this.$loading.requestRegionQualityQuota = true;
      await this.requestFirstCateQualifiedRate();
      await this.requestRegionQualityQuota({
        firstCategoryId: this.firstCateQualifiedRate[0].categoryId,
      });
    },
    async requestFirstCateQualifiedRate() {
      if (this.firstCateQualifiedRate.length) return;
      var res = await firstCateQualifiedRate();
      this.firstCateQualifiedRate = res;
    },
    // 区域质量指数regionQualityQuota、产业聚集指数industrialAggQuota、品类质量图解categoryQualityDiagramQuota
    async requestRegionQualityQuota({ firstCategoryId }, { token }) {
      // if (regionQualityQuotaMap.has(firstCategoryId)) {
      //   this.regionQualityQuota = regionQualityQuotaMap.get(firstCategoryId);
      //   return;
      // }
      var res = await qualityQuotaSituation(
        {
          qualityQuotaType: "regionQualityQuota",
          firstCategoryId,
        },
        { cancelToken: token }
      );
      this.regionQualityQuota = res.categoryRegionQualityList;
      regionQualityQuotaMap.set(firstCategoryId, res.categoryRegionQualityList);
    },
    async requestCategoryQualityDiagramQuota({ firstCategoryId }, { token }) {
      // if (categoryQualityDiagramQuotaMap.has(firstCategoryId)) {
      //   var categoryQualityDiagramQuota =
      //     categoryQualityDiagramQuotaMap.get(firstCategoryId);
      //   this.n5SecondCateRateList =
      //     categoryQualityDiagramQuota.n5SecondCateRateList;
      //   this.secondCateQualityRateList =
      //     categoryQualityDiagramQuota.secondCateQualityRateList.source;
      //   return;
      // }
      var res = await qualityQuotaSituation(
        {
          qualityQuotaType: "categoryQualityDiagramQuota",
          firstCategoryId,
        },
        { cancelToken: token }
      );

      let categoryQualityDiagramList = res.categoryQualityDiagramList;
      this.n5SecondCateRateList =
        categoryQualityDiagramList.n5SecondCateRateList;
      this.secondCateQualityRateList =
        categoryQualityDiagramList.secondCateQualityRateList.source;

      categoryQualityDiagramQuotaMap.set(
        firstCategoryId,
        categoryQualityDiagramList
      );
    },
    async requestIndustrialAggQuota({ firstCategoryId }, { token }) {
      // if (industrialAggQuotaMap.has(firstCategoryId)) {
      //   this.industrialAggQuota = industrialAggQuotaMap.get(firstCategoryId);
      //   return;
      // }
      var res = await qualityQuotaSituation(
        {
          qualityQuotaType: "industrialAggQuota",
          firstCategoryId,
        },
        { cancelToken: token }
      );

      this.industrialAggQuota = res.industrialQualityList;
      industrialAggQuotaMap.set(firstCategoryId, res.industrialQualityList);
    },
  },
  cancelOptions: {
    white: [
      "requestIndustrialAggQuota",
      "requestCategoryQualityDiagramQuota",
      "requestRegionQualityQuota",
      "requestFirstCateQualifiedRate",
    ],
  },
});

var area = [
  {
    name: "华东地区",
    subordinate: ["山东", "江苏", "安徽", "浙江", "福建", "上海", "台湾"],
    location: [119.4648, 29.2891],
  },
  {
    name: "华北地区",
    subordinate: ["北京", "天津", "河北", "山西", "内蒙古"],
    location: [115.1551, 42.2539],
  },
  {
    name: "华南地区",
    subordinate: ["广东", "广西", "海南", "香港", "澳门"],
    location: [111.2813, 23.6426],
  },
  {
    name: "华中地区",
    subordinate: ["湖北", "湖南", "河南", "江西"],
    location: [112.8, 31.1572],
  },
  {
    name: "西南地区",
    subordinate: ["重庆", "四川", "贵州", "云南", "西藏"],
    location: [97.5, 30.1904],
  }, //
  {
    name: "西北地区",
    subordinate: ["陕西", "甘肃", "青海", "宁夏", "新疆"],
    location: [91.5996, 39.6396],
  }, //
  {
    name: "东北地区",
    subordinate: ["辽宁", "吉林", "黑龙江"],
    location: [128.1445, 45.5156],
  },
];

let colors = [
  "#2995F2",
  "#218EE0",
  "#1B80CD",
  "#1774BA",
  "#1368A7",
  "#065E9F",
  "#00508B",
];
export const handleSeriesData = (data) => {
  var result = area.map((item, i) => {
    item.value = 0;
    data.forEach((option) => {
      if (item.subordinate.includes(option.name)) {
        item.value += option.value;
      }
    });
    return item;
  });
  // let total = result.reduce((total, item) => {
  //   return (total += item.value);
  // }, 0);
  result.sort((a, b) => a.value - b.value);

  return result.map((item, i) => {
    return {
      ...item,
      itemStyle: {
        color: colors[i],
      },
      // total,
      // ratio: item.value / total,
    };
  });
};
