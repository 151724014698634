import { onMounted, reactive, watch } from "vue";
import { loadMapJson } from "@/apis/commen";
import * as echarts from "echarts/core";
import maxBy from "lodash/maxBy";
import { effectScatter } from "./bmap";

export const createPieces = (min = 1, max, allValues) => {
  let realMin = Math.floor(min);
  if (min === 0) {
    const arr = [...allValues];
    arr.sort((a, b) => a - b);
    let cur = arr[0];
    while (arr.length && cur === 0) {
      cur = arr.shift();
    }
    realMin = Math.floor(cur);
  }
  var length = max > 10 ? 4 : 2;
  var progress = Math.ceil((max - realMin) / length);
  var startL = 60; // 起始亮度
  var endL = 99; // 结束亮度
  var l = (endL - startL) / length;

  var pieces = Array.from({ length }).map((a, i) => {
    var m = i * progress + realMin;
    return {
      gte: m,
      lte: m + progress,
      color: `hsl(214, 100%, ${endL - i * l - l}%)`,
    };
  });

  if (realMin !== Math.floor(min) || realMin === 0) {
    pieces.unshift({
      min: 0,
      max: 0,
      color: `hsl(214, 100%, ${endL}%)`,
    });
  }
  return pieces;
};

let requestMap = async (code = "China") => {
  var res = await loadMapJson(code);

  if (code === "China") {
    res.geo.features = res.geo.features.map((item) => {
      if (item.id.includes("xiang_gang")) {
        item.properties.cp[0] = item.properties.cp[0] + 2;
      } else if (item.id.includes("ao_men")) {
        item.properties.cp[1] -= 1;
      }
      return item;
    });
  }
  res.code = code;

  return res;
};

export const useMap = (request = requestMap) => {
  let queue = [];

  let option = reactive({
    backgroundColor: "transparent",
    title: {
      text: "",
      subtext: "",
      textStyle: {
        fontSize: 16,
      },
      subtextStyle: {
        fontSize: 12,
      },
      left: "center",
    },
    legend: {
      data: [],
    },
    tooltip: {
      show: true,
      borderColor: "transparent",
      formatter(item) {
        if (item.name) return `${item.name}:${item.value || 0}`;
        return false;
      },
    },
    visualMap: {
      show: true,
      type: "piecewise",
      orient: "horizontal",
      seriesIndex: 0,
      realtime: false,
      precision: 0,
      calculable: true,
      left: "center",
      bottom: 10,
      minOpen: true,
    },
    series: [
      {
        type: "map",
        data: [],
        geoIndex: 0,
        selectedMode: false,
      },
      effectScatter(),
    ],
    geo: {
      type: "map",
      map: "China",
      nameMap: {},
      itemStyle: {
        color: "#00508B",
        borderColor: "#000",
      },
      label: {
        show: true,
        color: "#fff",
      },
      emphasis: {
        itemStyle: {
          color: "#2A333D",
        },
        label: {
          color: "#fff",
        },
      },
      top: 80,
      bottom: 80,
      zoom: 1.2,
      roam: false,
    },
  });

  echarts.registerMap("China", {});

  const handleRequest = async (code) => {
    var res = await request(code);

    echarts.registerMap(res.code, res.geo);
    if (res.nameMap) {
      option.geo.nameMap = res.nameMap;
    }
    option.geo.map = res.code;
  };

  onMounted(() => {
    handleRequest("China");
  });

  return {
    option,
    queue,
    createPieces,
    request,
    handleRequest,
  };
};
